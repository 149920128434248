import React from 'react'
import Img from 'gatsby-image'
import ItemCSS from './item.module.css'

const Item = ({ items }) => (
  <a
    style={{
      flex: '0 1 100%',
    }}
    className={[ItemCSS.item, items.category, 'item'].join(' ')}
    rel="noopener noreferrer"
    href={items.link}
  >
    <Img className={ItemCSS.logo} fixed={items.logo.childImageSharp.fixed} />
    <span>{items.title}</span>
  </a>
)

export default Item
