import React from 'react'
import { Link } from 'gatsby'
import LinkPageCSS from './linkpage.module.css'

const LinkPage = ({ linkto, linktxt }) => (
  <Link className={LinkPageCSS.link} to={linkto}>
    {linktxt}
  </Link>
)

export default LinkPage
