import React from 'react'
import BoxCSS from './box.module.css'

const Box = ({ children }) => (
  <section
    style={{
      flex: '0 1 auto',
      alignSelf: 'auto',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignContent: 'stretch',
      alignItems: 'flex-start',
    }}
    className={BoxCSS.box}
  >
    {children}
  </section>
)

export default Box
