import React from 'react'
import { Link } from 'gatsby'

const Header = ({ size, title }) => (
  <h1
    className={size}
    style={{
      flex: '0 0 100%',
      alignSelf: 'flex-start',
      fontFamily: 'Ubuntu, sans-serif',
    }}
  >
    <Link
      to="/"
      style={{
        color: '#888',
        textDecoration: 'none',
      }}
    >
      {title}
    </Link>
  </h1>
)

export default Header
